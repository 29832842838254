/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.heading h4{
  border-bottom: 1px solid var(--ion-color-success);
}
.heading .toolbar{
  position: absolute;
  top:10px;
  right: 4px;
}
.mt-0{
  margin-top: 0;
}
.product-description{
  font-size: 10px !important;text-align: justify;
}
.product-description p{
  font-size: 10px !important;text-align: justify;
}

ion-title {
  position: relative;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
